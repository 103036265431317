import React from 'react';

const Notfound = () => {

  return (
    <div style={{ textAlign: "center" }}>
      <h1>404 Page not found &#58;&#40;&#40; </h1>
    </div>
  );
}

export default Notfound;
