import React from "react";
import Header from "../header/Header";
import { useTranslation } from "react-i18next";

const pStyle = {display : "flex", gap : '15px'}

const RefundPolicyScreen = () => {
    const { t } = useTranslation();
    return (
        <div className="body_wrapper">
            <Header
                hasNavigation={true}
                showCart={false}
                navId={'DELIVERY_POLICY'}
                title={t('static.refund_policy.title')}
            />
            <div className="main_container">
                <div className="container">
                    <strong>{ t('static.refund_policy.line1') }</strong>
                    <p>{ t('static.refund_policy.line2') }</p>
                    <p style={pStyle}><span>(1)</span><span>{ t('static.refund_policy.line3') }</span></p>
                    <p style={pStyle}><span>(2)</span><span>{ t('static.refund_policy.line4') }</span></p>
                    <p style={pStyle}><span>(3)</span><span>{ t('static.refund_policy.line5') }</span></p>
                    <p style={pStyle}><span>(4)</span><span>{ t('static.refund_policy.line6') }</span></p>
                    <p>{ t('static.refund_policy.line7') }</p>
                </div>
            </div>
        </div>
    );
};

export default RefundPolicyScreen;
